.facebook_button {
    border          : 0.2em solid #666666;
    border-radius   : 0.2em;
    color           : #666666;
    background-color: #fff;
    padding         : 1em;
    padding-left    : 1.5em;
    padding-right   : 1.5em;
    font-size       : 1em;
    cursor          : pointer;
    user-select     : none;
    width           : 250px;
}

.google_button svg {
    margin: 0 5px;
}

.google_button {
    background-color: #e93f2e;
    color           : white;
}

.sign_in_button {
    background-color: #2196f3;
    color           : white;
    width: auto;
}