.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.login_header{
  font-size: 25px;
  margin-top: 12vh;
  font-weight: 700;
  margin-bottom: 24px;
  text-align: center;
  line-height: 1.1;  
}

.option {
  min-height: 3em;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.option span {
  margin: 0.25em;
}

.option a {
  color: #999999;
}

.continue {
  border: 0.2em solid #666666;
  border-radius: 0.2em;
  color: #666666;
  background-color: #fff;
  padding: 1.5em;
  padding-left:3em;
  padding-right: 3em;
  font-size: 1.25em;
  cursor: pointer;
  user-select: none;
}

.option {
  min-height: 3em;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.option span {
  margin: 0.25em;
}

.option a {
  color: #999999;
}

.continue {
  border: 0.2em solid #666666;
  border-radius: 0.2em;
  color: #666666;
  background-color: #fff;
  padding: 1.5em;
  padding-left:3em;
  padding-right: 3em;
  font-size: 1.25em;
  cursor: pointer;
  user-select: none;
}
